import DocumentUtils from "./document-utils";
class StoredSetting {
    constructor(name, defaultValue, _onChangeCallbacks = []) {
        this.name = name;
        this.defaultValue = defaultValue;
        this._onChangeCallbacks = _onChangeCallbacks;
    }
    //#region Public Methods
    get(setting) {
        if (this._cachedValue === undefined) {
            const stringValue = localStorage.getItem("s:" + setting);
            this._cachedValue = stringValue
                ? this._parseValue(stringValue)
                : this.defaultValue;
        }
        return this._cachedValue;
    }
    set(setting, value) {
        this._cachedValue = value;
        localStorage.setItem("s:" + setting, this._stringifyValue(value));
        this._onChange(value);
    }
    addCallback(cb) {
        this._onChangeCallbacks.push(cb);
    }
    //#endregion Protected Methods
    //#region Private Methods
    _onChange(value) {
        this._onChangeCallbacks.forEach(cb => cb(value));
    }
}
//#endregion Abstract Types
//#region Classes
class StoredBoolean extends StoredSetting {
    constructor(name, defaultValue = false, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return value === "true";
    }
    _stringifyValue(value) {
        return value ? "true" : "false";
    }
}
class StoredInteger extends StoredSetting {
    constructor(name, defaultValue = 0, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        const number = parseInt(value);
        return Number.isNaN(number) ? this.defaultValue : number;
    }
    _stringifyValue(value) {
        return value.toString();
    }
}
class StoredString extends StoredSetting {
    constructor(name, defaultValue, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return value;
    }
    _stringifyValue(value) {
        return value;
    }
}
class StoredJson extends StoredSetting {
    constructor(name, defaultValue, callbacks) {
        super(name, defaultValue, callbacks);
    }
    _parseValue(value) {
        return JSON.parse(value);
    }
    _stringifyValue(value) {
        return JSON.stringify(value);
    }
}
class StoredSet extends StoredSetting {
    constructor(name, defaultValue, callbacks) {
        super(name, defaultValue ?? new Set(), callbacks);
    }
    _parseValue(value) {
        return new Set(JSON.parse(value));
    }
    _stringifyValue(value) {
        return JSON.stringify(Array.from(value));
    }
}
function getSettingsProxy(settingsBuilder) {
    const settings = {};
    for (const settingName in settingsBuilder) {
        const builder = settingsBuilder[settingName];
        settings[settingName] = new builder.cls(settingName, builder.defaultValue, builder.callbacks);
    }
    return new Proxy(settings, {
        get(target, prop) {
            return target[prop].get(prop);
        },
        set(target, prop, value) {
            target[prop].set(prop, value);
            SettingsSubscriptionManager.notifySubscribers(prop, value);
            return true;
        }
    });
}
const Settings = getSettingsProxy({
    isLightTheme: {
        cls: StoredBoolean,
        callbacks: [
            (value) => DocumentUtils.toggleLightTheme(value, true)
        ],
        defaultValue: false
    },
    suppressedDiagnosticLevels: {
        cls: (StoredSet),
        defaultValue: new Set()
    },
    suppressedDiagnosticCodes: {
        cls: (StoredSet),
        defaultValue: new Set()
    },
    showDiagnosticCodesOnUi: {
        cls: StoredBoolean,
        defaultValue: false
    },
    expandFoldersByDefault: {
        cls: StoredBoolean,
        defaultValue: false
    },
    formatXmlSpaces: {
        cls: StoredInteger,
        defaultValue: 2
    },
    showRefTooltips: {
        cls: StoredBoolean,
        defaultValue: true
    },
    showMissingRefTooltips: {
        cls: StoredBoolean,
        defaultValue: true
    },
    showNonEnglishDiagnostics: {
        cls: StoredBoolean,
        defaultValue: true
    }
});
export default Settings;
class _SettingsSubscriptionManager {
    constructor() {
        this._nextId = 0;
        this._subscriptions = new Map();
    }
    subscribe(setting, fn) {
        const id = this._nextId++;
        this._subscriptions.set(id, { setting, fn });
        return () => this._subscriptions.delete(id);
    }
    notifySubscribers(setting, value) {
        this._subscriptions.forEach(subscription => {
            if (subscription.setting === setting)
                subscription.fn(value);
        });
    }
}
export const SettingsSubscriptionManager = new _SettingsSubscriptionManager();
//#endregion Subscriptions
