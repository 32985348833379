import Settings from "lib/utils/settings";
import { RenderType } from "./index-data-types";
const { StringTableLocale } = window.S4TK.enums;
// HACK: just to remove circular dependency... I know this is gross
let _VIEWER_STATE;
export function setDiagnosticsViewerState(viewerState) {
    _VIEWER_STATE = viewerState;
}
var Diagnostics;
(function (Diagnostics) {
    function countExact(level, infos) {
        return infos.filter(info => info.level === level).length;
    }
    Diagnostics.countExact = countExact;
    function countAtLeast(level, infos) {
        return infos.filter(info => info.level >= level).length;
    }
    Diagnostics.countAtLeast = countAtLeast;
    function passesSettings(info) {
        if (Settings.suppressedDiagnosticLevels.has(info.level))
            return false;
        if (Settings.suppressedDiagnosticCodes.has(info.code))
            return false;
        if (!Settings.showNonEnglishDiagnostics) {
            const fileInfo = _VIEWER_STATE.mappings.getFileInfo(info.ownerId);
            if ((fileInfo.renderType === RenderType.StringTable)
                && (fileInfo.locale !== StringTableLocale.English))
                return false;
        }
        return true;
    }
    Diagnostics.passesSettings = passesSettings;
    function sorter(a, b) {
        const diff = b.level - a.level;
        if (diff !== 0)
            return diff;
        if (a.code > b.code)
            return 1;
        if (a.code < b.code)
            return -1;
        return 0;
    }
    Diagnostics.sorter = sorter;
})(Diagnostics || (Diagnostics = {}));
export default Diagnostics;
