import { EditorView, keymap } from "@codemirror/view";
import { Compartment, EditorState } from "@codemirror/state";
import { indentWithTab } from "@codemirror/commands";
import { basicSetup } from "codemirror";
import { xml } from "@codemirror/lang-xml";
import { json } from "@codemirror/lang-json";
import { python } from "@codemirror/lang-python";
import { javascript } from "@codemirror/lang-javascript";
import { darkEditor } from "./editor-theme-dark";
import { lightEditor } from "./editor-theme-light";
import { resourceKeyHoverTooltip } from "./tooltips";
var CodemirrorEditor;
(function (CodemirrorEditor) {
    const _themeCompartment = new Compartment();
    const _langCompartment = new Compartment();
    function create(parent, isDarkTheme, language) {
        return new EditorView({
            parent: parent,
            state: EditorState.create({
                extensions: [
                    basicSetup,
                    keymap.of([indentWithTab]),
                    _langCompartment.of(_getLanguageSupport(language)),
                    _themeCompartment.of(isDarkTheme ? darkEditor : lightEditor),
                    EditorState.readOnly.of(true),
                    resourceKeyHoverTooltip,
                ]
            })
        });
    }
    CodemirrorEditor.create = create;
    function updateContent(editor, content, language, scrollToTop = false) {
        const effects = [_langCompartment.reconfigure(_getLanguageSupport(language))];
        if (scrollToTop)
            effects.push(EditorView.scrollIntoView(0));
        editor.update([editor.state.update({
                changes: {
                    from: 0,
                    to: editor.state.doc.length,
                    insert: content
                },
                effects: effects
            })]);
    }
    CodemirrorEditor.updateContent = updateContent;
    function updateTheme(editor, isDarkTheme) {
        const theme = isDarkTheme ? darkEditor : lightEditor;
        editor.dispatch({
            effects: _themeCompartment.reconfigure(theme)
        });
    }
    CodemirrorEditor.updateTheme = updateTheme;
    function _getLanguageSupport(language) {
        switch (language) {
            case "xml":
                return xml();
            case "json":
                return json();
            case "js":
                return javascript();
            case "py":
                return python();
        }
    }
})(CodemirrorEditor || (CodemirrorEditor = {}));
export default CodemirrorEditor;
