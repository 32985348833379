import config from "src/config";
const { enums } = window.S4TK;
var ExternalTooltips;
(function (ExternalTooltips) {
    /**
     * Returns a `FetchedStringData` for the string with the given key.
     *
     * @param key Key of string (must be hex with 0x prefix)
     */
    async function fetchStringData(key) {
        try {
            const endpoint = _getSearchEndpoint(`strings?q=${key}&offset=0&limit=1&locales[]=0`);
            const res = await fetch(endpoint);
            if (!res.ok)
                return null;
            const { value, pack } = await _getFirstHit(res);
            return { text: value, pack, endpoint };
        }
        catch (e) {
            console.error(`Error fetching string '${key}':`, e);
            return null;
        }
    }
    ExternalTooltips.fetchStringData = fetchStringData;
    /**
     * Returns a `FetchedTuningData` for the tuning with the given instance.
     *
     * @param instance Instance of tuning (must be decimal)
     */
    async function fetchTuningData(instance) {
        try {
            const endpoint = _getSearchEndpoint(`tuning?q=${instance}&offset=0&limit=1`);
            const res = await fetch(endpoint);
            if (!res.ok)
                return null;
            const { instance_id, instance_type, group_id_hash, name } = await _getFirstHit(res);
            if (instance_id !== instance)
                return null;
            const type = enums.TuningResourceType.parseAttr(instance_type);
            return { type, name, group: group_id_hash, endpoint };
        }
        catch (e) {
            console.error(`Error fetching tuning '${instance}':`, e);
            return null;
        }
    }
    ExternalTooltips.fetchTuningData = fetchTuningData;
    /**
     * Returns a `FetchedImageData` for an image with the given resource key.
     * This does *not* actually fetch the image, but rather returns a URL from
     * where the image can be fetched.
     *
     * @param resKey Resource key of the DDS or DST image (must be hex TGI)
     */
    async function fetchImageData(resKey) {
        try {
            resKey = resKey.toUpperCase().replace(/:/g, "-").replace(/^2F7D0004/, "00B2D882");
            if (!resKey.startsWith("00B2D882"))
                return null;
            const instance = resKey.split("-")[2];
            const source = _getImageEndpoint(`0x${instance}`);
            return { source, endpoint: source };
        }
        catch (e) {
            console.error(`Error fetching image '${resKey}':`, e);
            return null;
        }
    }
    ExternalTooltips.fetchImageData = fetchImageData;
    function _getSearchEndpoint(path) {
        return `${config.TDESC_SEARCH_API}/${path}`;
    }
    function _getImageEndpoint(path) {
        return `${config.TDESC_ICON_API}/${path}`;
    }
    async function _getFirstHit(res) {
        const json = await res.json();
        const firstHit = json.hits.hits[0];
        return firstHit._source;
    }
})(ExternalTooltips || (ExternalTooltips = {}));
export default ExternalTooltips;
