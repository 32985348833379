//#region Render Types
export var RenderType;
(function (RenderType) {
    RenderType[RenderType["Unknown"] = 0] = "Unknown";
    RenderType[RenderType["Corrupt"] = 1] = "Corrupt";
    RenderType[RenderType["StringTable"] = 2] = "StringTable";
    RenderType[RenderType["Image"] = 3] = "Image";
    RenderType[RenderType["Deleted"] = 4] = "Deleted";
    RenderType[RenderType["PlainText"] = 5] = "PlainText";
})(RenderType || (RenderType = {}));
//#endregion
