import Diagnostics from "./diagnostics";
export var DisplayType;
(function (DisplayType) {
    DisplayType[DisplayType["Unspecified"] = 0] = "Unspecified";
    DisplayType[DisplayType["Tuning"] = 1] = "Tuning";
    DisplayType[DisplayType["StringTable"] = 2] = "StringTable";
})(DisplayType || (DisplayType = {}));
export var ExplorerCell;
(function (ExplorerCell) {
    function isActive(cell, activeId) {
        return cell.containedIds.has(activeId);
    }
    ExplorerCell.isActive = isActive;
    function passesSearch(cell, term) {
        term = term?.trim();
        if (!term)
            return true;
        return cell.filterName.toLowerCase().includes(term.toLowerCase());
    }
    ExplorerCell.passesSearch = passesSearch;
    function allDiagnostics(cell) {
        let baseDiagnostics;
        switch (cell.displayType) {
            case DisplayType.StringTable: {
                baseDiagnostics = [];
                cell.localeChoices.forEach(info => {
                    baseDiagnostics.push(...info.diagnostics);
                });
                break;
            }
            case DisplayType.Tuning: {
                baseDiagnostics = [
                    ...cell.tuningInfo.diagnostics,
                    ...(cell.simDataInfo?.diagnostics ?? []),
                ];
                break;
            }
            case DisplayType.Unspecified: {
                baseDiagnostics = cell.info.diagnostics;
                break;
            }
        }
        return baseDiagnostics
            .filter(d => Diagnostics.passesSettings(d))
            .sort(Diagnostics.sorter);
    }
    ExplorerCell.allDiagnostics = allDiagnostics;
})(ExplorerCell || (ExplorerCell = {}));
