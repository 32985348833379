export default class EventNotifier {
    constructor() {
        this._nextId = 0;
        this._subscribers = new Map();
    }
    subscribe(subscriber) {
        const id = this._nextId++;
        this._subscribers.set(id, subscriber);
        return () => this._subscribers.delete(id);
    }
    notify(value) {
        this._subscribers.forEach(sub => sub(value));
    }
}
