import EventNotifier from "../utils/event-notifier";
const ViewerEvents = {
    onExplorerSectionsChange: new EventNotifier(),
    onViewedFileChange: new EventNotifier(),
    onSearchTermChange: new EventNotifier(),
    onUserClickedFile: new EventNotifier(),
    onPackageNameChange: new EventNotifier(),
    onPackageUnloaded: new EventNotifier(),
};
export default ViewerEvents;
