export default class ViewerMappings {
    constructor() {
        this._fileIdToInfoMap = new Map();
        this._fileKeyToIdMap = new Map();
        this._stringKeyToInfoMap = new Map();
    }
    get fileIdToInfoMap() { return this._fileIdToInfoMap; }
    ;
    get fileKeyToIdMap() { return this._fileKeyToIdMap; }
    ;
    get stringKeyToInfoMap() { return this._stringKeyToInfoMap; }
    ;
    /**
     * Clears the contents of all contained maps.
     */
    clear() {
        this._fileIdToInfoMap.clear();
        this._fileKeyToIdMap.clear();
        this._stringKeyToInfoMap.clear();
    }
    /**
     * Returns the `ViewableFileInfo` for the file with the given ID.
     *
     * @param id ID of file to get
     */
    getFileInfo(id) {
        return this._fileIdToInfoMap.get(id);
    }
    /**
     * Returns the `ViewableFileInfo` for the file with the given key. Tuning keys
     * should be decimal instances, and other resource types should be full hex
     * resource keys using "-" to separate TGI values.
     *
     * @param key Resource key of file to get
     */
    getFileInfoByKey(key) {
        return this.getFileInfo(this._fileKeyToIdMap.get(key));
    }
    /**
     * Returns the `StringEntryInfo` for the string with the given key. Keys
     * should be numbers or 32-bit hex strings.
     *
     * @param key Key of string to get
     */
    getStringInfo(key) {
        if (typeof key === "string")
            key = parseInt(key, 16);
        if (isNaN(key))
            return undefined;
        return this._stringKeyToInfoMap.get(key);
    }
}
